
import { createGlobalStyle } from 'styled-components'
import theme from './index';

// fonts
import interbold from '../fonts/Inter-Bold.woff';
import interbold2 from '../fonts/Inter-Bold.woff2';

import interregular from '../fonts/Inter-Regular.woff';
import interregular2 from '../fonts/Inter-Regular.woff2';

import intersemibold from '../fonts/Inter-SemiBold.woff';
import intersemibold2 from '../fonts/Inter-SemiBold.woff2';


const GlobalStyle = createGlobalStyle`

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ (2015/04/28)*/
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  /* Additional resets */
  a {
    text-decoration: none;
    color: inherit;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
    outline: none;
    line-height: inherit;
    -webkit-appearance: none;
  }
  /* Fix antialiasing */
  *, *:before, *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  p, h1, h2, h3, h4, h5, h6, blockquote, pre, ul, ol, li, table, tr, th, td {
    user-select: text;
  }

  html {
    overflow-x: hidden;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  @font-face {
    font-family: 'Inter Regular';
    src: url(${interregular2}) format('woff2'),
        url(${interregular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter Bold';
    src: url(${interbold2}) format('woff2'),
        url(${interbold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter Semibold';
    src: url(${intersemibold2}) format('woff2'),
        url(${intersemibold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }


  main {
    transition: all 0.25s ease-in-out;
  }

  h1 {
    font-size: 46px;
    line-height: 140%;
    font-family: 'Inter Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #24292e;
    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 45px;
    }
  }

  h2 {
    font-family: 'Inter Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 34px;
    line-height: 45px;
    color: #24292e;
  }

  h3 {
    font-family: 'Inter Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 28px;
    line-height: 143%;
    color: #24292e;
  }

  h4, time {
    font-family: 'Inter Semibold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 31px;
    color: #24292e;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 174%;
    }
  }

  h5 {
    font-family: 'Inter Semibold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: #24292e;
  }

  h6 {
    font-family: 'Inter Semibold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #24292e;
  }

  textarea, select, input, button { outline: none; }

  input {
    padding: 10px 60px 10px 20px;
    width: 100%;
    margin-bottom: 25px;
    margin-top: 0px;
    border: 1px solid #bbb;
    border-radius: 3px;
    transition: all 0.25s ease-in-out;
  }

  p, input {
    font-family: 'Inter Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 174%;
    color: ${theme.text_color};
  }

  ul {
    list-style-type: disc;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  li {
    margin-left: 20px;
    font-family: 'Inter Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 174%;
    letter-spacing: 0.02em;
    color: ${theme.text_color};
  }

`

export default GlobalStyle;
