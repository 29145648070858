module.exports = [{
      plugin: require('/zeit/79221e21/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/79221e21/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scarborough","short_name":"Scarborough","start_url":"/","background_color":"#38a3d1","theme_color":"#38a3d1","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('/zeit/79221e21/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154156188-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"scarborough.capetown"},
    },{
      plugin: require('/zeit/79221e21/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/79221e21/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
