const theme = {
  black: '#1D1819',
  darkgrey: '#7F807F',
  midgrey: '#B2B2B2',
  lightgrey: '#E5E5E5',
  white: '#FFFFFF',
  teal: '#3B676B',
  productbg: '#2A686C',
  aqua: '#6BADA9',
  lightaqua: '#89CBC7',
  text_color: '#24292e',
  link_color: '#000000',
}

export default theme;
