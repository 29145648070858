import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Transition from '../components/transition'
import theme from '../theme';
import GlobalStyle from '../theme/globalstyle';
// import styled from 'styled-components';
import { BaseCSS } from 'styled-bootstrap-grid';
// import Wrapper from '../components/wrapper';

class Layout extends React.Component {

  render() {

    const { children, location } = this.props
    return (
      <>
        <BaseCSS />
        <GlobalStyle/>
        <StaticQuery
          query={graphql`
            query SiteTitleQueryLayouts {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <GlobalStyle />
              <ThemeProvider theme={theme}>
                  <Transition location={location}>
                    {children}
                  </Transition>
              </ThemeProvider>
            </>
          )}
        />
      </>
    )
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
